var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

import * as yup from 'yup';

var Rules = function () {
    function Rules(rules) {
        _classCallCheck(this, Rules);

        this.rules = [].concat(rules);
    }

    Rules.prototype.isRule = function isRule(arg) {
        if (Array.isArray(arg) && Array.isArray(arg[0]) && yup[arg[0][0]]) {
            return true;
        }

        return false;
    };

    Rules.prototype.handleObject = function handleObject(obj) {
        var handledObj = {};
        for (var key in obj) {
            handledObj[key] = obj.hasOwnProperty(key) && this.isRule(obj[key]) ? new Rules(obj[key]).toYup() : obj[key];
        }

        return handledObj;
    };

    Rules.prototype.processArgs = function processArgs(args) {
        var _this = this;

        return args.reduce(function (result, arg) {
            // test for arg !== null, because typeof null === "object", but null.constructor is Uncaught TypeError
            if (arg !== null && (typeof arg === 'undefined' ? 'undefined' : _typeof(arg)) === 'object' && arg.constructor === Object && !Array.isArray(arg)) {
                return [].concat(result, [_this.handleObject(arg)]);
            } else if (_this.isRule(arg)) {
                return [].concat(result, [new Rules(arg).toYup()]);
            }

            return [].concat(result, [arg]);
        }, []);
    };

    Rules.prototype.toYup = function toYup() {
        var _this2 = this;

        var _rules = this.rules,
            _rules$ = _rules[0],
            type = _rules$[0],
            typeArgs = _rules$.slice(1),
            rules = _rules.slice(1);

        if (!type || !yup[type]) {
            throw new Error('Type ' + type + ' does not exist');
        }

        var ruleTypeArgs = this.processArgs(typeArgs);

        var yupRule = yup[type].apply(yup, ruleTypeArgs);
        rules.forEach(function (_ref) {
            var _yupRule;

            var fn = _ref[0],
                rule = _ref.slice(1);

            if (!fn || !yupRule[fn]) {
                throw new Error('Method ' + fn + ' does not exist');
            }

            var args = _this2.processArgs(rule);
            yupRule = (_yupRule = yupRule)[fn].apply(_yupRule, args);
        });

        return yupRule;
    };

    return Rules;
}();

export { Rules as default };